
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>合作伙伴管理</el-breadcrumb-item>
      <el-breadcrumb-item>等级管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30" style="display: flex">
        <div style="margin-left: 50px; margin-right: 50px; line-height: 40px">
          当前列表共有 {{ ModelList.length }} 条记录
        </div>
        <el-button type="primary" @click="addLevel">新增伙伴等级</el-button>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ModelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="name"
            label="等级名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="ownerPercentage"
            label="伙伴分润占比"
            min-width="100px"
          >
          </el-table-column>
          <el-table-column label="操作" min-width="150px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="edits(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deletes(scope.row)"
                :disabled="scope.row.isUsed == '1' ? true : false"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="新增伙伴等级"
      :visible.sync="addFormDialog"
      width="30%"
      @close="addFormDialogClosed"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="等级名称：" prop="name">
          <el-input v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="伙伴分润占比：" prop="ratio">
          <el-input-number
            v-model="addForm.ratio"
            placeholder="请输入0-100间的整数"
            :min="0"
            :max="100"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addConfirm" v-preventReClick
          >确定</el-button
        >
        <el-button @click="addFormDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deleteDialog } from '@/libs/confirmDialog'
import { setGrade } from '@/api/partner.js'
import { getAllGrade } from './common.js'

export default {
  data () {
    return {
      ModelList: [],

      addFormDialog: false,
      addForm: {
        name: '',
        ratio: '',
        gradeId: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        ratio: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },
      IFedit: false,
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      this.ModelList = await getAllGrade()
      // console.log('222222', this.ModelList)
    },

    addLevel () {
      this.IFedit = false
      this.addFormDialog = true
    },
    addFormDialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.addForm.name = ''
      this.addForm.ratio = ''
      this.addForm.gradeId = ''
    },
    async AllAPI (quiese) {
      const res = await setGrade(quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.addFormDialog = false
      this.getList()

    },
    async addConfirm () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          let opt = ''
          if (this.IFedit) {
            opt = 2
            // console.log('修改')
          } else {
            opt = 1
            // console.log('新增')
          }
          this.AllAPI({ opt: opt, gradeName: this.addForm.name, percentage: this.addForm.ratio, gradeId: this.addForm.gradeId })

        }
      })
    },

    async edits (para) {
      this.addForm.name = para.name
      this.addForm.ratio = para.ownerPercentage
      this.addForm.gradeId = para.id
      this.IFedit = true
      this.addFormDialog = true
    },

    async deletes (para) {
      console.log(para)
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        this.AllAPI({ opt: 0, gradeId: para.id })
      }
    },

  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>
